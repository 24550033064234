







































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import RegistrationMapping from "@/apps/registrations-api/pages/components/RegistrationMapping/index.vue";
import {BButton, BCard, BContainer, BNav, BNavItem, BOverlay, BSpinner} from "bootstrap-vue";

@Component({
  components: {
    RegistrationMapping,
    BContainer,
    BCard,
    BOverlay,
    BNav,
    BNavItem,
    BSpinner,
    BButton
  }
})
export default class FlowNavigation extends Vue {
  @Prop({default: () => [], required: true})
  item: RegistrationsAPI.Flows.Flow

  @Prop({default: () => [], required: true})
  mappings: RegistrationsAPI.FlowMappings.FlowMapping[]

  hasBeenMapped(taskId) {
    return this.mappings.some(x=>x.taskId == taskId)
  }

  async goTo(task: RegistrationsAPI.Tasks.Task) {
    await this.$router.push({
      name: this.$route.name,
      params: {
        collection: this.$route.params.collection,
        flow: this.$route.params.flow,
        task: task.uniqueId
      }
    })
  }

  async back() {
    if(this.$route.params.task) {
      await this.$router.push({
        name: this.$route.name,
        params: {
          collection: this.$route.params.collection,
          flow: this.$route.params.flow
        }
      })
    } else {
      await this.$router.push({
        name: this.$route.name,
        params: {
          collection: this.$route.params.collection
        }
      })
    }
  }

  get selectedTask() {
    return this.$route.params.task;
  }
}


















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {BCol, BFormInput, BFormSelect, BRow} from "bootstrap-vue";

@Component({
  components: {BRow, BCol, BFormInput, BFormSelect}
})
export default class RegistrationQuestion extends Vue {
  @Prop({default: () => null, required: true})
  question: RegistrationsAPI.Tasks.Question

  @Prop({default: () => [], required: false})
  mappings: RegistrationsAPI.FlowMappings.FlowMapping[]

  @Prop({default: () => [], required: true})
  mappableFields: MaintenanceAPI.MappableFields.Item[]

  MappedTable = null;
  MappedColumn = null;

  get MappedItem() {
    return this.mappings.find(x => x.questionId === this.question.uniqueId);
  }

  get MappingTables() {
    const items = [{value: null, text: "Not Mapped"}]

    for (const mappableField of this.mappableFields) {
      items.push({
        value: mappableField.table,
        text: mappableField.table,
      })
    }

    return items;
  }
  get MappingColumns() {
    if(this.MappedTable == null) return [];

    const items = [];
    const table = this.mappableFields.find(x=>x.table == this.MappedTable)
    for (const column of table.columns) {
      items.push({
        value: column,
        text: column,
      })
    }

    return items;
  }

  @Watch("MappedItem", {deep: true, immediate: true})
  async watchMappedItem() {
    if(this.MappedItem != null) {
      this.$set(this, "MappedTable", this.MappedItem.mappedTable)
      this.$set(this, "MappedColumn", this.MappedItem.mappedColumn)
    }
  }


  @Watch("MappedColumn", {deep: true})
  async watchMappedColumn() {
    if(this.MappedItem?.mappedTable === this.MappedTable && this.MappedItem?.mappedColumn === this.MappedColumn) {
      return;
    }

    await this.$api.registrations.flowmappings.Create({
      taskId: this.$route.params.task,
      questionId: this.question.uniqueId,
      mappedTable: this.MappedTable,
      mappedColumn: this.MappedColumn,
      datapoint: this.question.type.datapoint
    })
    // Save Mapped Column
  }
}



































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {BButton, BCard, BContainer, BNav, BNavItem, BOverlay, BSpinner} from "bootstrap-vue";
// @ts-ignore
import RegistrationMapping from "@/apps/registrations-api/pages/components/RegistrationMapping/index.vue";
import Navigation from "@/apps/registrations-api/pages/components/Navigation/index.vue";
import {forEach} from "lodash";


@Component({
  components: {
    Navigation,
    RegistrationMapping,
    BContainer,
    BCard,
    BOverlay,
    BNav,
    BNavItem,
    BSpinner,
    BButton
  }
})
export default class RegistrationsDashboard extends Vue {
  loading = null;

  get loaded() {
    return (this.loading == null)
  }
}

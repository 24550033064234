


















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import RegistrationMapping from "@/apps/registrations-api/pages/components/RegistrationMapping/index.vue";
import {BButton, BCard, BContainer, BNav, BNavItem, BOverlay, BSpinner} from "bootstrap-vue";
import HomeNavigation from "@/apps/registrations-api/pages/components/Navigation/components/HomeNavigation.vue";
import CollectionNavigation
  from "@/apps/registrations-api/pages/components/Navigation/components/CollectionNavigation.vue";
import FlowNavigation from "@/apps/registrations-api/pages/components/Navigation/components/FlowNavigation.vue";

@Component({
  components: {
    FlowNavigation,
    CollectionNavigation,
    HomeNavigation,
    RegistrationMapping,
    BContainer,
    BCard,
    BOverlay,
    BNav,
    BNavItem,
    BSpinner,
    BButton
  }
})
export default class Navigation extends Vue {
  items: Array<RegistrationsAPI.Flows.FlowCollection> = []

  mappings: RegistrationsAPI.FlowMappings.FlowMapping[] = []

  async mounted() {
    await this.getGroups()
    await this.mappedQuestions();

  }

  @Watch("$route.params", {deep: true, immediate: true})
  async watchParams(newVal) {
    await this.mappedQuestions();
  }

  async mappedQuestions() {
    if(this.selectedCollection == null) return [];

    let taskIds = [];
    for (const flow of this.selectedCollection) {
      taskIds = [...taskIds, ...flow.taskIds]
    }

    const resp = await this.$api.registrations.flowmappings.Get("task", taskIds)
    this.$set(this, "mappings", resp);
  }

  async getGroups() {
    const items = await this.$api.registrations.flows.GetFlows();
    if(items)
    {
      this.$set(this, "items", items);
    }


    return;
  }

  get selectedCollection(): RegistrationsAPI.Flows.Flow[] {
    if(this.$route.params.collection == null) {
      return [];
    }

    return this.items.find(x=>x.name.toLowerCase() == this.$route.params.collection)?.items;
  }


  @Watch("$route.params.flow", {deep: true, immediate: true})
  async watchFlowParam(newVal) {
    if(this.currentFlow?.uniqueId != newVal)
      await this.getFlow()
  }

  currentFlow: RegistrationsAPI.Flows.Flow = null;
  async getFlow() {
      if(!this.$route.params.flow) return;

      this.currentFlow = null;
      this.currentFlow = await this.$api.registrations.flows.GetFlow(this.$route.params.flow, true);
  }

  get currentView() {
    if(this.$route.params.task != null) {
      return "task";
    }

    if(this.$route.params.flow != null) {
      return "flow";
    }

    if(this.$route.params.collection != null) {
      return "collection";
    }

    return "home";
  }
}















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {BTab} from "bootstrap-vue";
import RegistrationActionSet
  from "./RegistrationActionSet.vue";
import RegistrationQuestion
  from "./RegistrationQuestion.vue";

@Component({
  components: {BTab, RegistrationQuestion, RegistrationActionSet}
})
export default class RegistrationStep extends Vue {
  @Prop({default: () => [], required: true})
  mappableFields: MaintenanceAPI.MappableFields.Item[]

  @Prop({default: () => null, required: true})
  step: RegistrationsAPI.Tasks.Step

  @Prop({default: () => null, required: true})
  taskId: string

  @Prop({default: () => [], required: false})
  mappings: RegistrationsAPI.FlowMappings.FlowMapping[]
}

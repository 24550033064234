
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import RegistrationMapping from "@/apps/registrations-api/pages/components/RegistrationMapping/index.vue";
import {BButton, BCard, BContainer, BNav, BNavItem, BOverlay, BSpinner} from "bootstrap-vue";

@Component({
  components: {
    RegistrationMapping,
    BContainer,
    BCard,
    BOverlay,
    BNav,
    BNavItem,
    BSpinner,
    BButton
  }
})
export default class HomeNavigation extends Vue {
  @Prop({default: () => [], required: true})
  items: Array<RegistrationsAPI.Flows.FlowCollection>

  async goTo(collection: RegistrationsAPI.Flows.FlowCollection) {
    await this.$router.push({
      name: this.$route.name,
      params: {
        collection: collection.name.toLowerCase()
      }
    })
  }
}

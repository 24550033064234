













import { Vue, Component, Prop } from 'vue-property-decorator'
import RegistrationQuestion
  from "./RegistrationQuestion.vue";

@Component({
  components: {RegistrationQuestion}
})
export default class RegistrationActionSet extends Vue {
  @Prop({default: () => [], required: true})
  mappableFields: MaintenanceAPI.MappableFields.Item[]

  @Prop({default: () => null, required: true})
  question: RegistrationsAPI.Tasks.ActionSet

  @Prop({default: () => null, required: true})
  taskId: string

  @Prop({default: () => [], required: false})
  mappings: RegistrationsAPI.FlowMappings.FlowMapping[]
}












import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {BTab, BTabs} from "bootstrap-vue";
import RegistrationStep from "./components/RegistrationStep.vue";

@Component({
  components: {BTabs, BTab, RegistrationStep}
})
export default class RegistrationMapping extends Vue {
  Mappings: RegistrationsAPI.FlowMappings.FlowMapping[] = []

  task: RegistrationsAPI.Tasks.Task = null;


  activeStep = 0;


  @Watch("$route.params", {deep: true, immediate: true})
  async watchRoute(){
    await this.getMappableFields();
    await this.getItem();

    this.$set(this, "Mappings", await this.mappedTasksQuestions())
  }

  async getItem() {
    if(this.$route.params.task)
    {
      const tasks = await this.$api.registrations.tasks.GetTasks([this.$route.params.task]);
      this.task = tasks[0];
    }

  }

  MappableFields: MaintenanceAPI.MappableFields.Item[] = []
  async getMappableFields() {
    this.MappableFields = await this.$api.maintenance.mappableFields.GetBySystem("candidate");
  }

  async mappedTasksQuestions() {
    if(this.task == null) return [];

    return await this.$api.registrations.flowmappings.Get("task", [this.task.uniqueId])
  }

  get selectedTask() {
    return this.$route.params.task
  }
}
